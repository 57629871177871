<template>
    <div>
        <!--Create Drugs Modal-->
        <b-modal no-close-on-backdrop scrollable  title="Create Drug" class="modal-dark" v-model="createDrug">
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <label>Description</label>
                        <b-form-textarea v-model="newDrug.description"/>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>Source</label>
                        <b-form-input v-model="newDrug.source"/>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group>
                        <label>Notes</label>&nbsp;
                        <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(newDrug.notes, fields.notes[0])"/>
                        <b-input-group v-for="(e,i) in newDrug.notes" :key="i"  v-if="refresh">
                            <b-form-input v-model="newDrug.notes[i].text" />
                            <b-input-group-append v-if="e.length > 1">
                                <b-button variant="dark" @click="splice(i, newDrug.notes)">
                                    <fa-icon :icon="['fas', 'close']"/>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group>
                        <label>Documents</label>&nbsp;
                        <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(newDrug.documents, fields.notes[0])"/>
                        <b-input-group v-for="(e,i) in newDrug.documents" :key="i"  v-if="refresh">
                            <b-form-input v-model="newDrug.documents[i].description" />
                            <b-input-group-append v-if="e.length > 1">
                                <b-button variant="dark" @click="splice(i, newDrug.documents)">
                                    <fa-icon :icon="['fas', 'close']"/>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="createDrug=false">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="postDrug(newDrug)">Submit</b-button>
            </template>
        </b-modal>

        <!-- Drug edit Modal -->
        <b-modal no-close-on-backdrop scrollable  title="Edit Drug" class="modal-dark" id="drug-modal" size="lg" v-model="modalOpen">
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <label>Description</label>
                        <b-form-textarea v-model="openDrug.description"/>
                    </b-form-group>
                </b-col>
                <b-col sm="6">
                    <b-form-group>
                        <label>Source</label>
                        <b-form-input v-model="openDrug.source"/>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group>
                        <label>Notes</label>&nbsp;
                        <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(openDrug.notes, fields.notes[0])"/>
                        <b-input-group v-for="(e,i) in openDrug.notes" :key="i"  v-if="refresh">
                            <b-form-input v-model="openDrug.notes[i].text" />
                            <b-input-group-append v-if="e.length > 1">
                                <b-button variant="dark" @click="splice(i, openDrug.notes)">
                                    <fa-icon :icon="['fas', 'close']"/>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col sm="12">
                    <b-form-group>
                        <label>Documents</label>&nbsp;
                        <fa-icon :icon="['fas', 'circle-plus']" style="cursor: pointer;" @click="push(openDrug.documents, fields.notes[0])"/>
                        <b-input-group v-for="(e,i) in openDrug.documents" :key="i"  v-if="refresh">
                            <b-form-input v-model="openDrug.documents[i].description" />
                            <b-input-group-append v-if="e.length > 1">
                                <b-button variant="dark" @click="splice(i, openDrug.documents)">
                                    <fa-icon :icon="['fas', 'close']"/>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="modalOpen=false">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="putDrug(openDrug)">Submit</b-button>
            </template>
        </b-modal>

        <!-- Drug show Modal -->
        <b-modal no-close-on-backdrop scrollable  title="Drug" class="modal-dark" id="drug-modal" size="lg" v-model="modalSOpen">
            <b-col sm="12">
                <label>Description: {{ showDrug.description }}</label>
            </b-col>
            <b-col sm="12">
                <label>Source: {{ showDrug.source }}</label>
            </b-col>
            <b-col sm="12">
                <label>Notes:</label>
                <ul>
                    <li v-for="(note, id) in showDrug.notes" :key="id">{{ note.text }}</li>
                </ul>
            </b-col>
            <b-col sm="12">
                <label>Documents:</label>
                <ul>
                    <li v-for="(note, id) in showDrug.documents" :key="id">{{ note.description }}</li>
                </ul>
            </b-col>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="modalSOpen=false">Cancel</b-button>
            </template>
        </b-modal>

        <!-- Drug Table -->
        <b-card style="width: 100%; height: 100%">
            <div slot="header">
                <span class="d-flex w-100 justify-content-between">
                    <h5 style="margin-bottom: 0"><b>Drug Manager</b></h5>
                    <fa-icon :icon="['fas', 'square-plus']" size="lg" style="float: right; cursor: pointer;" @click="openCreateDrug"/>
                </span>
            </div>
            <v-client-table @row-click="rowClicked" ref="dTable" style="width: 100%" :columns="columns" :data="drugs" :options="tOptions" :theme="theme" class="dataTable">
                <div slot="description" slot-scope="props">
                    <span v-if="props.row.description !== null">{{props.row.description}}</span>
                    <span v-else>XX</span>
                </div>
                <div slot="source" slot-scope="props">
                    <span v-if="props.row.source !== null">{{props.row.source}}</span>
                    <span v-else>XX</span>
                </div>
                <div slot="info" slot-scope="props">
                    <div  style="float: right; cursor: pointer;">
                        <fa-icon :icon="['fas', 'info']" size="lg" @click="openSModal(props.row)"/>&nbsp;
                        <fa-icon :icon="['fas', 'pen-to-square']" size="lg" @click="openEModal(props.row)"/>
                    </div>
                </div>
                <div slot="h__info">
                    <span style="float: right; margin-bottom: 0">Info/Edit</span>
                </div>
            </v-client-table>
            <b-tooltip v-if="tooltipRender" ref="tooltip" :target="target" :title="tooltipTitle" triggers=""></b-tooltip>
        </b-card>
    </div>
</template>

<script>
    import Vue from 'vue'
    import risc from '@/services/risc.js'
    import { ClientTable } from 'vue-tables-2'

    Vue.use(ClientTable)

    export default {
        data() {
            return {
                columns: ['description', 'source', 'info'],
                createDrug: false,
                fields: {
                    description: '',
                    source: '',
                    notes: [
                        { text: ''}
                    ],
                    documents: [
                        { description: ''}
                    ]
                },
                modalOpen: false,
                modalSOpen: false,
                newDrug: {},
                openDrug: {},
                showDrug: {},
                refresh: true,
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                tooltipTitle:"",
                tooltipRender:true,
                target: '',
                theme: 'bootstrap4',
                tOptions: {
                    sortable: ['description', 'source', 'notes', 'documents'],
                    filterable: ['description', 'source', 'notes', 'documents'],
                    orderBy: {column: 'name', ascending: true},
                    headings: {
                        description: 'Description',
                        source: 'Source',
                        info: 'Info/Edit'
                    },
                    sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                    pagination: {
                        chunk: 5,
                        edge: false,
                        nav: 'scroll'
                    },
                    perPage: 25,
                    skin: 'table table-striped table-hover',
                },
                drugs: []
            }
        },
        methods: {
            getDrugs() {
                risc.getDrugs().then(response => {
                    this.drugs = response.data
                })
            },
            rowClicked(item) {
                //ctrl click to make tooltip appear with id
                if (item.event.ctrlKey) {
                    this.tooltipRender = false;
                    this.tooltipTitle = item.row._id;
                    item.event.target.id = item.row._id;
                    this.target = item.row._id;
                    //restart tooltip so it can map to its new position
                    Vue.nextTick().then(() => {
                        this.tooltipRender = true;
                        Vue.nextTick().then(() => {
                            this.$refs.tooltip.$emit('open')
                            setTimeout(function () {
                                //make the tooltip disappear automatically
                                this.$refs.tooltip.$emit('close');
                            }.bind(this), 4000)
                        });
                    });
                }
            },
            openCreateDrug(){
                this.newDrug = {};
                this.newDrug = JSON.parse(JSON.stringify(this.fields))
                this.createDrug = true;
            },
            openEModal(info) {
                risc.getDrug(info.drugId).then(response => {
                    this.openDrug = response.data
                })
                this.openDrug = JSON.parse(JSON.stringify(info))
                Object.keys(this.fields).forEach(field => {
                    if(this.openDrug[field] === undefined) {
                        this.openDrug[field] = JSON.parse(JSON.stringify(this.fields[field]))
                    }
                    else if(Array.isArray(this.openDrug[field]) && this.openDrug[field].length === 0) {
                        this.openDrug[field] = JSON.parse(JSON.stringify(this.fields[field]))
                    }
                })
                this.modalOpen = true
            },
            openSModal(info) {
                this.showDrug = JSON.parse(JSON.stringify(info))
                risc.getDrug(info.drugId).then(response => {
                    this.showDrug = response.data
                })
                this.modalSOpen = true
            },
            postDrug(){
                this.createDrug = false;
                Object.keys(this.fields).forEach(field => {
                    if(JSON.stringify(this.newDrug[field]) === JSON.stringify(this.fields[field]) || this.newDrug[field] === undefined){
                        delete this.newDrug[field];
                    }
                })
                risc.postDrug(this.newDrug).then(() => {
                    this.getDrugs();
                })
            },
            putDrug() {
                this.modalOpen = false
                risc.putDrug(this.openDrug).then(() => {
                    this.getDrugs()
                })
            },
            push(field, item){
                field.push(JSON.parse(JSON.stringify(item)));
                this.refresh=false;
                this.refresh=true;
            },
            splice(index, field) {
                field.splice(index, 1);
                this.refresh=false;
                this.refresh=true;
            }
        },
        created() {
            this.getDrugs()
        }
    }
</script>